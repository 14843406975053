export const blogSchema = [
  {
    id: "1",
    title: "HOW TO GET RID OF OLD SCARS?",
    schemaCode: `"@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.cutis.org.in/blog/frequent-skin-problems-in-children"
  },
  "headline": "HOW TO GET RID OF OLD SCARS?",
  "description": "Injuries and their marks are impossible to ignore. While they do not have any harm, you may be tired of seeing them in a particular area. It not only irritates at some points but also spoils the overall look. That time you look for remedies to get rid of them. If you are in a similar situation, here, we will walk you through a few practical and successful non-surgical treatments",
  "image": "https://www.cutis.org.in/assets/img/blogs/blog-18.jpg",  
  "author": {
    "@type": "Organization",
    "name": "cutis",
    "url": "https://cutis.org.in/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "cutis",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
    }
  },
  "datePublished": "" `,
  },
  {
    id: "2",
    title: "GUIDE TO MAINTAIN BEAUTIFUL NAILS",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/frequent-skin-problems-in-children"
          },
          "headline": "GUIDE TO MAINTAIN BEAUTIFUL NAILS",
          "description": "You don't have to visit the salon regularly to have beautiful nails. The combination of a balanced diet, thoughtful beauty regimen and good personal hygiene can give you healthy nails.
        To counteract the effects of skipping extra hand washes and salon visits, here are a few things exactly what you need for your nails - but first and foremost how to ensure that your nails are healthy? Here's what you need to look for.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-15.jpeg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "3",
    title: "BENEFITS OF ANTI-AGEING TREATMENT",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/-benefits-of-anti-ageing-treatment"
          },
          "headline": "BENEFITS OF ANTI-AGEING TREATMENT",
          "description": "Old age has been a fact of life since its creation. Humans go through various stages of life from childhood to adolescence and youth is the best part of life in terms of health. Good health, strong muscles, an efficient immune system, acute memory, and a healthy brain are the hallmarks of ideal youth. Hormones function at their peak in young years.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-14.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "4",
    title: "TIPS TO MANAGE ACNE FOR TEENAGERS",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/tips-to-manage-acne-for-teenagers"
          },
          "headline": "TIPS TO MANAGE ACNE FOR TEENAGERS",
          "description": "Acne usually begins in adulthood or during adolescence when the body increases the production of a hormone called androgen. This hormone promotes the production of an oily substance called sebum, which mixes with dead skin cells and hair follicles. This buildup of skin cells, oil, and hair comes together and creates stress, which breaks down and causes skin inflammation.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-13.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "5",
    title:
      "TIME TO PAMPER YOUR SKIN? OR WHAT’S THE OCCASION? FOR FACIAL REJUVENATION PROCEDURES.",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/time-to-pamper-your-skin-or-what-is-the-occasion-for-facial-rejuvenation-procedures"
          },
          "headline": "TIME TO PAMPER YOUR SKIN? OR WHAT’S THE OCCASION? FOR FACIAL REJUVENATION PROCEDURES.",
          "description": "As you age, your skin begins to tell a story: the lazy summer days spent in the sun or the rough times of the menstrual cycle. You might gain or lose weight but undoubtedly, your skin can be a physical witness to a better life.
        People wish to have youthful-looking skin and the signs of aging aren’t always acceptable. Wrinkles, Acne scars, Sunspots, and Cellulite, can be corrected with the help of Skin Rejuvenation treatment. This process is meant to improve the appearance of your skin and give a younger look, and smoother skin.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog12.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
         `,
  },
  {
    id: "6",
    title: "REASONS TO CHOOSE LASER HAIR REMOVAL OVER SHAVING OR WAXING!",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/reasons-to-choose-laser-hair-removal-over-shaving-or-waxing"
          },
          "headline": "REASONS TO CHOOSE LASER HAIR REMOVAL OVER SHAVING OR WAXING!",
          "description": "Tired of shaving, threading, and waxing methods to get rid of unwanted hairs?
        
        Using the traditional hair removal methods such as waxing, shaving, and threading are less effective, temporary, time-consuming and for many, it’s frustrating. These methods remain temporarily effective but the recurring costs may increase over time.
        A permanent solution is to opt for laser hair reduction. It provides an effective, long-lasting solution and it can be done on any part of the body for unwanted hair. Although this method does not eliminate the hair completely, it reduces hair growth and causes a delay in the growth of new hair by 50-95%, and makes new hair that grows thinner and easier to maintain.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-11.jpeg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "7",
    title: "HEALTHY SKIN CARE HABITS TO GET GORGEOUS SKIN FOR YEARS..!",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/healthy-skin-care-habits-to-get-gorgeous-skin-for-years"
          },
          "headline": "HEALTHY SKIN CARE HABITS TO GET GORGEOUS SKIN FOR YEARS..!",
          "description": "If I could go back in time, I'd be more careful with my skin.' We hear these lines too often. In contrast to the fashion that changes or new gadgets, you're wearing your skin for your life. There are no updates or changes to your outfit.
        Check out the following article as we discuss the healthy skin care routine;
        Individuals with dry skin may experience itching, fine lines, slight to severe scaling or peeling, cracks, and/or fissures and pain that may be intense. Itching may also result in secondary infection of the skin.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-10.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "8",
    title: "WHAT IS THE BEST WAY TO MANAGE FACIAL HAIR GROWTH?",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/what-is-the-best-way-to-manage-facial-hair-growth"
          },
          "headline": "WHAT IS THE BEST WAY TO MANAGE FACIAL HAIR GROWTH?",
          "description": "For most women, facial and body hair is frowned upon and can even be an embarrassing problem. “Normal” amounts of facial hair vary from person to person. Some individuals have very little facial and body hair, while others can have a fair amount. Facial and body hair are part of our genetic makeup and ethnicity, which means that genetics plays a role in determining the facial hair patterns.
        Excess facial hair in women can also be caused by underlying hormonal disturbance – Hirsutism. Hirsutism is excess hair in areas where it’s typically seen in men, such as the face and lower abdomen. Hypertrichosis, on the other hand, can increase hair anywhere on the body.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-1.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
         `,
  },
  {
    id: "9",
    title: "TAKING CARE OF YOUR SCALP",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/taking-care-of-your-scalp"
          },
          "headline": "TAKING CARE OF YOUR SCALP",
          "description": "Taking care of your hair is as good as taking good care of yourself. It is very essential to wash your hair regularly.
        
        Here are a few tips to wash your hair:
        Wet the hair completely.
        Use lukewarm water
        Apply adequate amount of shampoo to the scalp
        Scrub gently
        Allow the shampoo to stay for few minutes
        Rinse well
        Gently dry your hair with a towel",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-2.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        
        `,
  },
  {
    id: "10",
    title: "HAIR LOSS – WHAT IS NORMAL AND WHAT NEEDS TREATMENT",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/hair-loss-what-is-normal-and-what-needs-treatment"
          },
          "headline": "HAIR LOSS – WHAT IS NORMAL AND WHAT NEEDS TREATMENT",
          "description": "Everyone loses hair. It is normal to lose about 50 to 100 hair every day. If you see bald patches or a lot of thinning, you may be experiencing hair loss.
        There are many causes of hair loss. Women may notice hair loss after giving birth. People under a lot of stress can see noticeable hair loss. Some diseases and medical treatments can cause hair loss.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-3.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
         `,
  },
  {
    id: "11",
    title: "HAIR, SKIN AND NAILS",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/hair-skin-and-nails"
          },
          "headline": "HAIR, SKIN AND NAILS",
          "description": "Skin is the largest organ in our body but most often than not, it is the most neglected part. On “World skin health day” here are a few common skin queries answered to help you achieve a healthy skin, nails and hair.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-4.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "12",
    title: "SKIN CARE TIPS FOR SUMMER",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/skin-care-tips-for-summer"
          },
          "headline": "SKIN CARE TIPS FOR SUMMER",
          "description": "Summer is around the corner and practically everyone is busy planning their summer getaways. Follow these tips to avoid sun taking a toll on your skin and hair in when you are out in the harsh light.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-5.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "13",
    title: "PIGMENTATION ON YOUR FACE",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/pigmentation-on-your-face"
          },
          "headline": "PIGMENTATION ON YOUR FACE",
          "description": "Pigmentation of the face is a very common cosmetic skin problem. It presents as dark spots or blotchy patch or discoloration of the entire face due to an accumulation of an excessive pigment called melanin. Pigmentation may be due to many reasons, most commonly due to excess of sun exposure, Hormonal variations and Post- inflammatory pigmentation",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-6.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
         `,
  },
  {
    id: "14",
    title: "ARE SUNSCREENS REALLY NEEDED?",
    schemaCode: `
        
      {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/are-sunscreens-really-needed"
          },
          "headline": "ARE SUNSCREENS REALLY NEEDED?",
          "description": "Although the sun is unquestionably the source of all energy here on earth, prolonged exposure to it can damage the skin. With growing interest in outdoor recreational activities, it has become essential to take precautions to prevent sun-induced skin damage.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-7.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "15",
    title: "HOW TO BEAT ACNE???",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/how-to-beat-acne"
          },
          "headline": "HOW TO BEAT ACNE???",
          "description": "Acne medically termed ‘acne vulgaris’ is one of the most common skin disorders faced by the general population. It is a long-term disease that occurs when the hair follicles are clogged with dead skin and oil produced from the oil glands in the skin. It is usually seen as blackheads, whiteheads, raised reddish lesions called papules, pus-filled lesions and sometimes scarring.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-8.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "17",
    title: "MALE PATTERN BALDNESS, & HOW IS IT TREATED?",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.cutis.org.in/blog/tips-to-battle-dry-skin"
          },
          "headline": "TIPS TO BATTLE DRY SKIN",
          "description": "Skin, the largest organ of our body is a key indicator of overall health. It serves as a water-containing barrier that protects us from harmful environmental factors. Skin is usually able to maintain sufficient moisture despite of water being lost by evaporation. Dryness occurs when skin loses moisture or cannot retain it.",
          "image": "https://www.cutis.org.in/assets/img/blogs/blog-9.jpg",  
          "author": {
            "@type": "Organization",
            "name": "cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": ""
        }
    
        `,
  },
  {
    id: "18",
    title: "HOW TO GET RID OF OLD SCARS?",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://cutis.org.in/blog/how-to-get-rid-of-old-scars"
          },
          "headline": "How To Get Rid Of Old Scars?",
          "description": "Injuries and their marks are impossible to ignore. While they do not have any harm, you may be tired of seeing them in a particular area. It not only irritates at some points but also spoils the overall look.",
          "image": "https://cutis.org.in/assets/img/blogs/blog-18.jpg",  
          "author": {
            "@type": "Organization",
            "name": "Cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": "2022-8-23"
        }
            
        `,
  },
  {
    id: "19",
    title: "BOTOX INJECTION: TREATMENT AND PURPOSE",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://cutis.org.in/blog/botox-injection-treatment-and-purpose"
          },
          "headline": "Botox Injection: Treatment and Purpose",
          "description": "Botox (Botulinum Toxin) is a drug that is injected underneath the skin via small needles. The purpose of using Botox injections is to reduce wrinkles and fine lines.",
          "image": "https://cutis.org.in/assets/img/blogs/blog-19.jpg",  
          "author": {
            "@type": "Organization",
            "name": "Cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": "2022-08-23"
        }
                    
        `,
  },
  {
    id: "20",
    title: "LASER TREATMENT MODALITIES AVAILABLE FOR SCARS",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://cutis.org.in/blog/laser-treatment-modalities-available-for-scars"
          },
          "headline": "Laser Treatment Modalities Available For Scars",
          "description": "Laser is a non-invasive and non-surgical therapy that uses a beam of light and heat, stimulate collagen production resulting in smoother, clear, and fine-looking skin.",
          "image": "https://cutis.org.in/assets/img/blogs/blog-20.jpg",  
          "author": {
            "@type": "Organization",
            "name": "Cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": "2022-10-11"
        }
                   
        `,
  },
  {
    id: "21",
    title: "PRP -HOW IT WORKS, WHAT IS THE EFFECTIVENESS & SIDE EFFECTS?",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://cutis.org.in/blog/prp-how-it-works-what-is-the-effectiveness-&-side-effects"
          },
          "headline": "PRP -How It Works, What Is The Effectiveness & Side Effects?",
          "description": "Plasma is one of the four main components of blood, known to promote healing.",
          "image": "https://cutis.org.in/assets/img/blogs/blog-21.jpg",  
          "author": {
            "@type": "Organization",
            "name": "Cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": "2022-10-11"
        }
                   
        `,
  },
  {
    id: "22",
    title: "A WAY TO SPOTLESS SKIN - CARBON PEEL TREATMENT FOR MEN!",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://cutis.org.in/blog/a-way-to-spotless-skin-carbon-peel-treatment-for-men"
          },
          "headline": "A Way To Spotless Skin - Carbon Peel Treatment For MEN!",
          "description": "Few of the skin problems prevalent among Men include; acne, oily skin, whiteheads, blackheads, dull skin, pigmentation, dark spots and dirty pores. To fight all these issues, everyday skincare proves to be mildly fruitful.",
          "image": "https://cutis.org.in/assets/img/blogs/blog-22.jpg",  
          "author": {
            "@type": "Organization",
            "name": "Cutis",
            "url": "https://cutis.org.in/"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Cutis",
            "logo": {
              "@type": "ImageObject",
              "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": "2023-01-12"
        }
                
        `,
  },
  {
    id: "23",
    title: "WHAT IS SKIN PURGING",
    schemaCode: `
        
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://cutis.org.in/blog/what-is-skin-purging"
          },
          "headline": "What is Skin purging",
          "description": "Skin purging is a term used to describe the process of the skin going through an initial breakout or worsening of existing acne when we start a new skin care routine or product.",
          "image": "https://cutis.org.in/assets/img/blogs/blog-23.jpg",  
          "author": {
            "@type": "Organization",
            "name": "Cutis Hospital"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Cutis Hospital",
            "logo": {
              "@type": "ImageObject",
              "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
            }
          },
          "datePublished": "2023-02-22"
        }
               
        `,
  },
  {
    id: "34",
    title: "TIPS FOR HYDRATING AND NOURISHING YOUR SKIN",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/tips-for-hydrating-and-nourishing-your-skin"
    },
    "headline": "TIPS FOR HYDRATING AND NOURISHING YOUR SKIN",
    "description": "A healthy & glowing skin is everyone's dream. After reading the blog, achieving this skin is no more a dream. A vital secret behind nourishing skin is a proper lifestyle and healthy balanced diet before a skincare routine. You are a few minutes away from knowing the secret tips for hydrating and nourishing skin. Continue reading to know more.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-34.png",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-07-10"       
        `,
  },
  {
    id: "33",
    title: "DEMYSTIFYING PSORIASIS: SYMPTOMS, TRIGGERS, AND TREATMENT OPTIONS",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/demystifying-psoriasis-symptoms-triggers-and-treatment-options"
    },
    "headline": "DEMYSTIFYING PSORIASIS: SYMPTOMS, TRIGGERS, AND TREATMENT OPTIONS",
    "description": "Recent research says psoriasis incidence is too high among people aged 26 and above. The reason behind the causes of the disease is associated with various conditions. Some common conditions are type 2 diabetes, heart disorders, inflammatory bowel disease, anxiety, mental stress, and psoriatic arthritis. We don't need to panic as the disease is not deadly. But still, the necessary precautions or the root cause of the disease has to be determined for healthy well-being.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-33.png",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-07-10"
  
        `,
  },
  {
    id: "32",
    title: "",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/common-skin-issues-and-how-to-treat-them"
    },
    "headline": "COMMON SKIN ISSUES AND HOW TO TREAT THEM",
    "description": "Your skin is your body's largest organ, as a protective barrier against the external environment. However, it is also susceptible to many common skin issues affecting your overall well-being. Understanding these conditions and their treatment options is crucial for maintaining healthy skin. This article will explore a few prevalent skin problems and provide effective remedies to help you take charge of your skin health.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-32.png",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-06-25"
  
        `,
  },
  {
    id: "31",
    title: " VITILIGO - SYMPTOM, TREATMENT AND CAUSES",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/vitiligo-symptom-treatment-and-causes"
    },
    "headline": "VITILIGO - SYMPTOM, TREATMENT AND CAUSES",
    "description": "Are you noticing patches of depigmented skin that are spreading? Perhaps you or someone you know is experiencing a loss of skin color, causing visible white patches that are puzzling and concerning. If this sounds familiar, you may be dealing with vitiligo. Vitiligo is a skin disorder characterized by the loss of melanocytes, the cells responsible for producing the pigment that gives color to our skin, hair, and eyes. It affects people of all ages and ethnicities, causing physical changes and potential emotional and psychological impacts.",
    "image": "https://cutis.org.in/assets/img/blogs/vitiligo-31.png",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-06-21"
  
        `,
  },
  {
    id: "30",
    title: "HOW DOES HAIR TRANSPLANT SURGERY WORK",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/how-does-hair-transplant-surgery-work"
    },
    "headline": "HOW DOES HAIR TRANSPLANT SURGERY WORK?",
    "description": "Hair transplant surgery is a surgical procedure that involves transplanting hair follicles from one area of the scalp to another area that is balding or thinning. The procedure is typically performed under local anaesthesia. It is usually an outpatient procedure, meaning patients can go home the same day.",
    "image": "https://cutis.org.in/assets/img/blogs/blog31.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-05-22"
  
        `,
  },
  {
    id: "29",
    title: "TYPE OF ACNE SCARS",
    schemaCode: ` "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/type-of-acne-scars"
    },
    "headline": "TYPE OF ACNE SCARS",
    "description": "Acne is a common skin condition that affects many individuals, often leaving behind unwanted scars. These scars can have a lasting impact on self-esteem and confidence. At Cutis, we understand the emotional toll that acne scars can take on an individual. That's why we are here to provide comprehensive information about the different types of acne scars and the treatment options available. Our goal is to empower you to make informed decisions about your skincare journey, by restoring your skin's radiance and feeling confident in your own skin once again.",
    "image": "https://cutis.org.in/assets/img/blogs/blog30.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-05-15" `,
  },
  {
    id: "28",
    title: "ACNE IN CHILDREN: CAUSES, SYMPTOMS, AND MANAGEMENT",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/acne-in-children-causes-symptoms-and-management"
    },
    "headline": "ACNE IN CHILDREN: CAUSES, SYMPTOMS, AND MANAGEMENT",
    "description": "Acne is a common skin condition that can affect children of any age but is typically associated with adolescence. While skin inflammation is generally not a serious ailment, it can cause acute pain and influence a youngster's confidence.",
    "image": "https://cutis.org.in/assets/img/blogs/blog29.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-04-27"
  
        `,
  },
  {
    id: "27.1",
    title: "THE BEST FOODS FOR THICK AND HEALTHY HAIR",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/the-best-foods-for-thick-and-healthy-hair"
    },
    "headline": "THE BEST FOODS FOR THICK AND HEALTHY HAIR",
    "description": "Having thick & healthy hair is one of the signs of healthy well-being. Our diet and nutrition intake significantly impact hair health. Also, genetics plays a vital role in determining the thickness and quality of our hair. Therefore, a well-balanced diet high in nutrients is necessary to have thick & healthy hair. Following are some of the best foods and hair care tips for growing healthy hair. For further information, book an appointment with Cutis today!",
    "image": "https://cutis.org.in/assets/img/blogs/blog28.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-04-21"
  
        `,
  },
  {
    id: "27",
    title: "THE EFFECTS OF POLLUTION ON YOUR SKIN AND HOW TO PROTECT IT",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/the-effects-of-pollution-on-your-skin-and-how-to-protect-it"
    },
    "headline": "THE EFFECTS OF POLLUTION ON YOUR SKIN AND HOW TO PROTECT IT",
    "description": "Pollution is a developing issue in the world today, and it influences our current environment as well as our wellbeing. The effects of pollution on our skin are one aspect that is frequently overlooked. Even though there are ways to prevent pollution from harming your skin such as a skin care clinic , the effects can still range from being mild or even severe",
    "image": "https://cutis.org.in/assets/img/blogs/blog27.jpg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-04-17"
  
        `,
  },
  {
    id: "26",
    title: "HAIR LOSS IN MEN: CAUSES, TREATMENTS, AND PREVENTION STRATEGIES",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/hair-loss-in-men"
    },
    "headline": "HAIR LOSS IN MEN: CAUSES, TREATMENTS, AND PREVENTION STRATEGIES",
    "description": "Hair loss is a typical issue that influences many men around the world. It is a condition that can be brought on by a variety of things, from genetics to environmental factors. While there are numerous answers for preventing hair loss, consultation from a hair care centre is the most essential choice in order to be secure from all dimensions. Understanding the causes, treatments, and ways to prevent hair loss is crucial because it can have a significant impact on a person's self-esteem and quality of life.",
    "image": "https://cutis.org.in/assets/img/blogs/blog26.jpg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-04-13"
  
        `,
  },
  {
    id: "25",
    title:
      "YOU TRIED THE COSMELAN PEEL. WHAT TO EXPECT NEXT, ON YOUR SKIN-LIGHTENING JOURNEY AFTER THE COSMELAN PEEL?",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/what-is-cosmelan-peel-treatment"
    },
    "headline": "YOU TRIED THE COSMELAN PEEL. WHAT TO EXPECT NEXT, ON YOUR SKIN-LIGHTENING JOURNEY AFTER THE COSMELAN PEEL?",
    "description": "Cosmelan peel is a powerful blemish removal treatment that helps to reduce the appearance of dark spots and discoloration. It is a professional-grade product that is applied by qualified professionals, and it works by exfoliating the top layers of skin and lightening the appearance of dark spots. It can be used to reduce the appearance of various types of hyperpigmentation, such as sun damage, age spots, and acne marks. This treatment is popular among those who want to achieve a more even skin tone and brighter complexion.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-25.jpg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-03-29"
  
        `,
  },
  {
    id: 24,
    title: "PRP BEFORE AND AFTER: RESULTS OF HAIR RESTORATION THERAPY",
    schemaCode: `  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://cutis.org.in/blog/prp-before-and-after"
  },
  "headline": "PRP BEFORE AND AFTER: RESULTS OF HAIR RESTORATION THERAPY",
  "description": "Hair Restoration Therapy is an effective and scientifically proven method to restore hair and increase hair density. Platelet Rich Plasma (PRP) therapy is an advanced technique used to restore hair growth and reduce hair loss. It is a simple, non-surgical procedure, which has been used successfully in hair restoration for decades.",
  "image": "https://cutis.org.in/assets/img/blogs/blog-24.jpg",  
  "author": {
    "@type": "Organization",
    "name": "cutis hospitals",
    "url": "https://cutis.org.in/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "cutis hospitals",
    "logo": {
      "@type": "ImageObject",
      "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
    }
  },
  "datePublished": "2023-02-11"
`,
  },
  {
    id: 24,
    title: "PRP BEFORE AND AFTER: RESULTS OF HAIR RESTORATION THERAPY",
    schemaCode: `  "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/prp-before-and-after"
    },
    "headline": "PRP BEFORE AND AFTER: RESULTS OF HAIR RESTORATION THERAPY",
    "description": "Hair Restoration Therapy is an effective and scientifically proven method to restore hair and increase hair density. Platelet Rich Plasma (PRP) therapy is an advanced technique used to restore hair growth and reduce hair loss. It is a simple, non-surgical procedure, which has been used successfully in hair restoration for decades.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-24.jpg",  
    "author": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "cutis hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-02-11"
  `,
  },
  {
    id: 38,
    title:
      "DEMYSTIFYING VITILIGO: CLEARING MISCONCEPTIONS AND STATING THE FACTS",
    schemaCode: `  "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/demystifying-vitiligo-clearing-misconceptions-and-stating-the-facts"
    },
    "headline": "DEMYSTIFYING VITILIGO: CLEARING MISCONCEPTIONS AND STATING THE FACTS",
    "description": "Vitiligo, a skin condition characterized by the loss of skin colour, often leads to misconceptions and misunderstandings. In this informative blog, we aim to provide accurate information about vitiligo while debunking common myths. Join us as we uncover the truth about this condition and why consulting a dermatologist at Cutis Hospital is the key to healthier skin.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-38.png",  
    "author": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-09-22",
    "dateModified": "2023-09-22"
  `,
  },
  {
    id: 37,
    title:
      "COMPREHENSIVE GUIDE TO ECZEMA: SYMPTOMS, TREATMENTS, CAUSES, AND TYPES",
    schemaCode: `  "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/comprehensive-guide-to-eczema-symptoms-treatments-causes-and-types"
    },
    "headline": "COMPREHENSIVE GUIDE TO ECZEMA: SYMPTOMS, TREATMENTS, CAUSES, AND TYPES",
    "description": "Eczema, also known as atopic dermatitis, is a widespread skin condition affecting millions of people worldwide. This comprehensive guide will delve into the symptoms, treatment options, causes, and various types of eczema to provide you with a well-rounded understanding of this skin disorder.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-37.png",  
    "author": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-09-18",
    "dateModified": "2023-09-18"
  `,
  },
  {
    id: 36,
    title: "EFFECTIVE MELASMA TREATMENTS: ACHIEVE EVEN-TONED CLEARER SKIN",
    schemaCode: `  "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/effective-melasma-treatments-achieve-even-toned-clearer-skin"
    },
    "headline": "EFFECTIVE MELASMA TREATMENTS: ACHIEVE EVEN-TONED CLEARER SKIN",
    "description": "Melasma, a prevalent skin condition, often manifests as brown or greyish patches on the face, particularly on the cheeks, forehead, nose, and upper lip. While it's generally harmless, it can be a concern for those experiencing it. Fortunately, a range of effective treatments exists to manage and reduce melasma's appearance. In this blog, we'll delve into the best melasma treatments available in Bangalore and how they can help you attain clearer and more even-toned skin. If you're searching for 'the best melasma treatments near me,' your answer lies here. Keep reading to discover more!",
    "image": "https://cutis.org.in/assets/img/blogs/blog-36.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-09-08",
    "dateModified": "2023-09-08"
  `,
  },
  {
    id: 35,
    title: "DISCOVER THE BENEFITS OF LASER HAIR REDUCTION IN BANGALORE",
    schemaCode: `  "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://cutis.org.in/blog/discover-the-benefits-of-laser-hair-reduction-in-bangalore"
    },
    "headline": "DISCOVER THE BENEFITS OF LASER HAIR REDUCTION IN BANGALORE",
    "description": "Dealing with unwanted hair growth can be both frustrating and time-consuming, affecting our self-esteem. Fortunately, laser hair reduction has emerged as an effective and long-term solution to this common problem. This blog delves into the advantages of laser hair reduction and how it can revolutionize your grooming routine. If you're searching for 'laser hair reduction near me,' on Google, you are in the right place.",
    "image": "https://cutis.org.in/assets/img/blogs/blog-35.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "url": "https://cutis.org.in/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Cutis Hospitals",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
      }
    },
    "datePublished": "2023-09-01",
    "dateModified": "2023-09-01"
  `,
  },
  {
    id: 36,
    title:
      "Revitalize Your Appearance: The Complete Handbook on Botox & Fillers Treatment",
    schemaCode: `   "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://cutis.org.in/blog/revitalize-your-appearance-the-complete-handbook-on-botox-&-fillers-treatment"
  },
  "headline": "REVITALIZE YOUR APPEARANCE: THE COMPLETE HANDBOOK ON BOTOX & FILLERS TREATMENT",
  "description": "In the relentless pursuit of maintaining youthful and vibrant skin, individuals across the globe explore a plethora of cosmetic procedures. Among these, Botox and fillers emerge as prominent choices, revolutionizing the realm of dermatology with their minimally invasive approach. Botox, derived from Botulinum Toxin, sourced from the bacterium Clostridium botulinum, and fillers, primarily composed of hyaluronic acid, stand as potent solutions in the fight against visible signs of aging.

Introduction to Botox:

Botox operates by temporarily paralyzing muscles in targeted regions, thereby diminishing the appearance of wrinkles and fine lines. Its primary focus lies in addressing dynamic wrinkles, those resulting from repetitive facial expressions such as smiling or frowning.",
  "image": "https://cutis.org.in/assets/img/blogs/blog-53.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Cutis",
    "url": "https://cutis.org.in/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Cutis",
    "logo": {
      "@type": "ImageObject",
      "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
    }
  },
  "datePublished": "2024-03-26",
  "dateModified": "2024-03-26"

  `,
  },
  {
    id: 37,
    title: "Decoding Male Pattern Baldness: Causes, Stages, and Solutions",
    schemaCode: `   "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://cutis.org.in/blog/decoding-male-pattern-baldness-causes-stages-and-solutions"
  },
  "headline": "DECODING MALE PATTERN BALDNESS: CAUSES, STAGES, AND SOLUTIONS",
  "description": "Hair fall is a common concern for many men, impacting their appearance, confidence, and self-esteem. Among the various types of hair loss, male pattern baldness is one of the most common and recognizable conditions. This comprehensive guide will delve into the intricacies of male pattern baldness, exploring its causes, symptoms, progression, treatment options, preventive measures, and best practices for managing this condition effectively.

Understanding Male Pattern Baldness

Male pattern baldness, also called androgenetic alopecia, is a hereditary condition characterized by gradually thinning hair follicles in a specific pattern. This pattern typically begins with a receding hairline and thinning crown, eventually leading to partial or complete baldness in some men. While it can affect men of any age, it is most commonly observed in older adults.

Causes of Hair Loss in Men

The primary cause of male pattern baldness is genetics, with inherited genes from both parents playing a significant role in determining susceptibility to the condition. However, other factors such as hormonal imbalances, ageing, stress, and certain medical issues can also contribute to hair loss in men.",
  "image": "https://cutis.org.in/assets/img/blogs/blog-54.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Cutis",
    "url": "https://cutis.org.in/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Cutis",
    "logo": {
      "@type": "ImageObject",
      "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
    }
  },
  "datePublished": "2024-03-26",
  "dateModified": "2024-03-26"
  `,
  },
  {
    id: 38,
    title:
      "Definitive Strategies for Banishing Pimples and Enhancing Skin Radiance",
    schemaCode: `    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://cutis.org.in/blog/definitive-strategies-for-banishing-pimples-and-enhancing-skin-radiance"
  },
  "headline": "DEFINITIVE STRATEGIES FOR BANISHING PIMPLES AND ENHANCING SKIN RADIANCE",
  "description": "Dealing with pimples can be a persistent challenge, affecting people of all ages and skin types. These blemishes not only cause physical discomfort but also impact self-esteem and confidence levels. However, armed with the right knowledge and effective strategies, managing pimples and attaining clear, radiant skin is entirely achievable. In this comprehensive guide, we'll delve into the underlying causes of pimples, practical tips for prevention, natural remedies, and professional treatment options to help you combat acne and embrace a healthier complexion.

Understanding Pimples: Causes and Characteristics

Pimples, or acne, arise when hair follicles become clogged with oil and dead skin cells, creating an ideal environment for bacterial growth. The resulting inflammation leads to the formation of various types of pimples, including blackheads, whiteheads, papules, pustules, nodules, and cysts. To effectively address pimples, it's crucial to understand their underlying causes, which can range from hormonal fluctuations and genetics to lifestyle factors and environmental influences.

How to Reduce Pimples on the Face: Effective Strategies",
  "image": "https://cutis.org.in/assets/img/blogs/blog-55.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Cutis",
    "url": "https://cutis.org.in/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Cutis",
    "logo": {
      "@type": "ImageObject",
      "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
    }
  },
  "datePublished": "2024-03-26",
  "dateModified": "2024-03-26"
  `,
  },
  {
    id: 39,
    title:
      "Cracking the Code of Hair Fall: Understanding Triggers, Treatments, and Restoration",
    schemaCode: `   "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://cutis.org.in/blog/cracking-the-code-of-hair-fall-understanding-triggers-treatments-and-restoration"
  },
  "headline": "CRACKING THE CODE OF HAIR FALL: UNDERSTANDING TRIGGERS, TREATMENTS, AND RESTORATION",
  "description": "Hair loss is a pervasive issue affecting individuals of all ages, genders, and backgrounds. Whether it's noticing a few extra strands in the shower drain or experiencing a receding hairline, the emotional impact of hair loss can be profound, affecting self-esteem and confidence. Understanding the intricacies of hair loss, including its causes, available treatments, and regrowth techniques, is essential in effectively addressing this common concern.

Understanding Hair Fall

Hair fall, scientifically known as alopecia, encompasses a spectrum of conditions ranging from mild shedding to significant hair loss. While it's normal to lose around 50 to 100 hairs daily, excessive hair fall requires attention. Various factors contribute to hair loss, and gaining insight into these factors is the first step toward finding solutions.",
  "image": "https://cutis.org.in/assets/img/blogs/blog-56.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Cutis",
    "url": "https://cutis.org.in/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Cutis",
    "logo": {
      "@type": "ImageObject",
      "url": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png"
    }
  },
  "datePublished": "2024-03-26",
  "dateModified": "2024-03-26"
  `,
  },
];
